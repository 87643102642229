body {
   margin: 0;
   font-size: "16px";
   line-height: "22px";
   font-family: 'Gotham', sans-serif;
   -ms-overflow-style: scrollbar;
   -webkit-font-smoothing: subpixel-antialiased;
   background: #E4E4E5;
   color: #5E6365;
}

.viewport {
   min-height: calc(100vh - 40px);
}

.wrapper {
   min-height: 100vh;
}

a {
   color: #000;
   text-decoration: none;
}

a:hover {
   color: #999;
   text-decoration: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
   line-height: 1.2em;
   color: inherit;
}

h2 {
   font-size: 40px;
   line-height: 46px;
}

h3 {
   font-size: 32px;
   line-height: 38px;
}

h4 {
   font-size: 24px;
   line-height: 30px;
}

h5 {
   font-size: 20px;
   line-height: 26px;
}

h6 {
   font-size: 18px;
   line-height: 24px;
}

/* loader-wrapper
--------------------------------------------------------------------------------------------------------*/
.loader-wrapper {
   position: fixed;
   display: flex;
   align-items: center;
   justify-content: center;
   background: rgba(255, 255, 255, 0.6);
   transition: all .2s cubic-bezier(0.35, 0, 0.25, 1);
   z-index: 2000;
   height: 100%;
   width: 100%;
   top: 0;
   left: 0;
   opacity: 0;
   visibility: hidden;
}

body.loader-open {
   overflow: hidden;
}

.loader-open .loader-wrapper {
   opacity: 1;
   visibility: visible;
}

.sk-chase {
   width: 72px;
   height: 72px;
   position: relative;
   animation: sk-chase 2.5s infinite linear both;
}

.sk-chase-dot {
   width: 100%;
   height: 100%;
   position: absolute;
   left: 0;
   top: 0;
   animation: sk-chase-dot 2.0s infinite ease-in-out both;
}

.sk-chase-dot:before {
   content: '';
   display: block;
   width: 25%;
   height: 25%;
   background-color: #111;
   border-radius: 100%;
   animation: sk-chase-dot-before 2.0s infinite ease-in-out both;
}

.sk-chase-dot:nth-child(1) {
   animation-delay: -1.1s;
}

.sk-chase-dot:nth-child(2) {
   animation-delay: -1.0s;
}

.sk-chase-dot:nth-child(3) {
   animation-delay: -0.9s;
}

.sk-chase-dot:nth-child(4) {
   animation-delay: -0.8s;
}

.sk-chase-dot:nth-child(5) {
   animation-delay: -0.7s;
}

.sk-chase-dot:nth-child(6) {
   animation-delay: -0.6s;
}

.sk-chase-dot:nth-child(1):before {
   animation-delay: -1.1s;
}

.sk-chase-dot:nth-child(2):before {
   animation-delay: -1.0s;
}

.sk-chase-dot:nth-child(3):before {
   animation-delay: -0.9s;
}

.sk-chase-dot:nth-child(4):before {
   animation-delay: -0.8s;
}

.sk-chase-dot:nth-child(5):before {
   animation-delay: -0.7s;
}

.sk-chase-dot:nth-child(6):before {
   animation-delay: -0.6s;
}

@keyframes sk-chase {
   100% {
      transform: rotate(360deg);
   }
}

@keyframes sk-chase-dot {

   80%,
   100% {
      transform: rotate(360deg);
   }
}

@keyframes sk-chase-dot-before {
   50% {
      transform: scale(0.4);
   }

   100%,
   0% {
      transform: scale(1.0);
   }
}

.footer {
   bottom: 0;
   width: 100%;
   text-align: center;
   color: #444444;
   font-size: 14px;
   margin-bottom: 5px;
}

.infinite-scroll-component {
   overflow-y: hidden !important;
}

.marquee {
   height: 4mm;
   background-color: transparent;
   margin-top: -4mm;
   overflow: hidden;
   color: black;
}

.set-label {
   color: rgba(0, 0, 0, 0.6);
   font-family: "Roboto", "Helvetica", "Arial", sans-serif;
   font-weight: 400;
   font-size: 1rem;
   line-height: 1.4375em;
   letter-spacing: 0.00938em;
}

.marquee p {
   display: inline-block;
   padding-left: 100%;
   animation: marquee 30s linear infinite;
   font-size: 14px;
   font-weight: bolder;
   margin-top: 0;
   width: 200px;
}

@keyframes marquee {
   0% {
      transform: translate(-100%, 0);
   }

   100% {
      transform: translate(0, 0);
   }
}

.richText td,
.richText tr,
.richText th,
.richText table {
   border: 1px solid grey;
   border-collapse: collapse;
   padding: 3px;

}

.richText th {
   background-color: #f3f3f3;
}

.richText table {
   width: 100%;
}

fieldset {
   border: none;
}

fieldset.disabled {
   opacity: 0.45;
}
